import apiClient from "@/api/apiClient";
let CITIES = [];

export const getCities = async () => {
  if (CITIES.length === 0) {
    const result = await apiClient.get("/api/cities");
    CITIES = result.data.cities;
  }
  return CITIES;
};

export const getShops = async () => {
  const result = await apiClient.get("/api/shops");
  return result.data.shops;
};

export const getBranches = async () => {
  const result = await apiClient.get("/api/branches");
  return result.data.branches;
};

export const createBranch = async (branch) => {
  const result = await apiClient.post("/api/branch/new", { ...branch });
  return result.data;
};

export const updateBranch = async (storeId, branch) => {
  const result = await apiClient.put(`/api/branch/${storeId}`, { ...branch });
  return result.data;
};

export const deleteBranch = async (storeId) => {
  const result = await apiClient.delete(`/api/branch/${storeId}`);
  return result.data;
};
