<template>
  <div class="home">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Товары ({{ filters.total }})
      <div class="flex items-center">
        <el-input
          v-model="filters.query"
          size="small"
          placeholder="Поиск"
          @keypress.enter.native="handleFiltersChange"
          class="mr-2"
        >
          <i
            v-if="filters.query"
            slot="suffix"
            style="cursor: pointer"
            class="el-input__icon el-icon-close"
            @click="
              filters.query = null;
              handleFiltersChange();
            "
          ></i>
        </el-input>
        <el-button
          size="small"
          round
          plain
          type="info"
          :disabled="loading"
          @click="handleFiltersChange"
          >Поиск</el-button
        >
      </div>
    </h1>

    <el-table
      border
      size="small"
      v-loading="loading"
      :data="products"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="index"
        width="50"
        label="N"
        :index="indexMethod"
      >
      </el-table-column>
      <!--      <el-table-column prop="sku" label="SKU" width="120"></el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop="sku_kaspi"-->
      <!--        label="SKU Kaspi"-->
      <!--        width="120"-->
      <!--      ></el-table-column>-->
      <el-table-column prop="name" label="Название">
        <template slot-scope="scope">
          [<router-link
            target="_blank"
            class="text-blue-500"
            :to="{
              name: 'Product',
              params: { id: scope.row.product_id },
            }"
            >{{ scope.row.product_id }}</router-link
          >] - {{ scope.row.name }} <br />
          {{ scope.row.brand }} <br />
          {{ scope.row.sku }} <br />
          {{ scope.row.sku_kaspi }}
        </template>
      </el-table-column>
      <el-table-column
        align="right"
        prop="average_price"
        label="Ср. цена"
        width="120"
      ></el-table-column>
      <el-table-column
        align="right"
        prop="min_price"
        label="Мин. цена"
        width="120"
      ></el-table-column>

      <el-table-column
        v-for="x in branches"
        :key="x.id"
        align="right"
        :label="x.warehouse_name"
        width="120"
      >
        <template v-if="scope.row.prices[x.id]" slot-scope="scope">
          {{ scope.row.prices[x.city_id].price }} <br />
          <span class="text-xs text-gray-400">{{
            dateTimeFormat(scope.row.prices[x.city_id].damped_at)
          }}</span>
        </template>
      </el-table-column>

      <!--      <el-table-column width="120" align="right" prop="brand" label="Действия">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button-->
      <!--            @click.stop="editProduct(scope.row)"-->
      <!--            size="small"-->
      <!--            plain-->
      <!--            type="primary"-->
      <!--            icon="el-icon-edit"-->
      <!--          ></el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="filters.total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => handleFiltersChange('page')"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getKaspiProducts } from "@/api/product";
import { getBranches, getCities } from "@/api/branch";
import LocalStorageService from "@/services/LocalStorageService";
import { dateTimeFormat } from "@/helpers/formatter";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Home",
  data: () => ({
    branches: [],
    cities: [],
    products: [],
    loading: false,
    uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,
    filters: {
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 20,
      query: null,
    },
    selected_products_id: [],
  }),
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
    page() {
      return this.$route.query.page;
    },
    query() {
      return this.$route.query.query;
    },
  },
  async created() {
    if (this.page) {
      this.filters.page = parseInt(this.page, 10);
    }
    if (this.query) {
      this.filters.query = this.query;
    }
    await this.getProducts();
    this.branches = (await getBranches()).filter((b) => b.is_main);
    console.log("branches", this.branches);
    this.cities = await getCities();
  },
  methods: {
    async getProducts() {
      this.loading = true;
      const result = await getKaspiProducts(this.filters);
      const { products, pages_count, total } = result;
      this.products = products;
      this.filters.pages_count = pages_count;
      this.filters.total = total;
      this.loading = false;
    },
    editProduct(product) {
      this.$router.push({
        name: "kaspi-products-item",
        params: { kaspiProductId: product.id },
      });
    },
    changeUrlSearchParams() {
      this.$router.push({
        query: {
          type: this.filters.type,
          page: this.filters.page,
          damping: this.filters.damping,
          query: this.filters.query,
        },
      });
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getProducts();
    },
    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
    selectProduct(productId) {
      const index = this.selected_products_id.indexOf(productId);
      if (index >= 0) {
        this.selected_products_id.splice(index, 1);
      } else {
        this.selected_products_id.push(productId);
      }
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime * 1000);
    },
  },
};
</script>
