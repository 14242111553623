import { format } from "date-fns";

export const dateTimeFormat = (timestamp) => {
  return format(timestamp, "dd.MM HH:mm");
};

export const dateFormat = (timestamp) => {
  return format(timestamp, "dd.MM");
};

export const numberFormat = (number) => {
  return new Intl.NumberFormat().format(number);
};
